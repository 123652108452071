import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { NavbarLG } from "./NavbarLG";
import { NavbarMobile } from "./NavbarMobile";
import Logo from "../../images/assessments-logo.png";
import { router } from "../../routes";
import { createRef } from "react";
import { useSelector } from "react-redux";
import UserController from "./UserController";
import ColorModeIconDropdown from "../../theme/ColorModeIconDropdown";

const Navbar = () => {
  const { user } = useSelector((state) => state.auth);

  const onNavLinkClick = (e) => {
    router.navigate(e.currentTarget.getAttribute("to") ?? "/");
  };

  const appBarRef = createRef();

  return (
    <AppBar ref={appBarRef} position="sticky">
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toolbar disableGutters>
          <img
            onClick={onNavLinkClick}
            alt="assessment-pg-icon"
            style={{ cursor: "pointer" }}
            src={Logo}
          />
        </Toolbar>
        <Box display={"flex"} alignItems={"center"}>
          <NavbarMobile
            navContainer={appBarRef.current}
            onLinkClick={onNavLinkClick}
          />
          <NavbarLG onLinkClick={onNavLinkClick} />
          {user && <UserController />}
          <ColorModeIconDropdown />
        </Box>
      </Container>
    </AppBar>
  );
};

export default Navbar;
