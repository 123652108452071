import { Download } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { STATIC_PATH } from "../../api/api_routes";
import { useDispatch } from "react-redux";
import {
  assignRoleToUser,
  sendUserInvitation,
  updateAccountStatus,
} from "../../reducers/UsersReducer";
import RoleSelector from "../RoleSelector";

const UsersTableRow = ({
  id,
  name,
  email,
  cvPath,
  event,
  created,
  accountStatus,
  jobRole,
  isActive,
  role,
  onClick,
  mobileNumber,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  let status = useMemo(() => {
    let status;
    switch (accountStatus) {
      default:
      case 0:
        status = { label: "Created", color: "primary" };
        break;
      case 1:
        status = { label: "Invited", color: "secondary" };
        break;
      case 2:
        status = { label: "Active", color: "success" };
        break;
    }

    return status;
  }, [accountStatus]);

  const handleOnClick = () => {
    onClick({
      id,
      name,
      email,
      cvPath,
      event,
      created,
      accountStatus,
      jobRole,
      mobileNumber,
      role,
      isActive,
      ...rest,
    });
  };

  const onAccountStatusUpdate = (e, isActive) => {
    setIsProcessing(true);
    dispatch(updateAccountStatus({ id, isActive })).finally(() => {
      setIsProcessing(false);
    });
  };

  const onAccountInvitation = (e) => {
    stopPropogation(e);

    setIsProcessing(true);
    dispatch(sendUserInvitation(id)).finally(() => {
      setIsProcessing(false);
    });
  };

  const onRoleChanged = (e) => {
    setIsProcessing(true);
    dispatch(assignRoleToUser({ email, role: e.target.value })).finally(() => {
      setIsProcessing(false);
    });
  };

  const stopPropogation = (e) => {
    e.stopPropagation();
  };

  return (
    <TableRow
      className={`${
        isProcessing ? "is-processing" : onClick ? "on-click-active" : ""
      }`}
      onClick={!isProcessing && onClick ? handleOnClick : undefined}
    >
      <TableCell>
        <Checkbox onClick={stopPropogation} />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell align="right">{email}</TableCell>
      <TableCell align="right">
        {cvPath && (
          <IconButton
            component="a"
            href={`${STATIC_PATH}${cvPath}`}
            download={true}
            size="small"
            onClick={stopPropogation}
            disabled={isProcessing}
          >
            <Download />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="center">{event}</TableCell>
      <TableCell align="right">
        {new Date(created).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">{jobRole}</TableCell>
      <TableCell align="right">
        <Typography sx={{ textWrap: "nowrap" }}>
          {`${mobileNumber?.length === 9 ? "+94" : ""} ${mobileNumber ?? ""}`}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Chip size="medium" {...status} />
      </TableCell>
      <TableCell align="right">
        <FormControl component="fieldset">
          <FormControlLabel
            onClick={stopPropogation}
            value={isActive ? "Active" : "Disabled"}
            control={
              <Switch
                checked={isActive}
                disabled={isProcessing}
                onClick={stopPropogation}
                onChange={onAccountStatusUpdate}
                color="primary"
              />
            }
            label={isActive ? "Active" : "Disabled"}
            labelPlacement="bottom"
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <RoleSelector
          disabled={isProcessing}
          onClick={stopPropogation}
          onChange={onRoleChanged}
          value={role}
          containerProps={{
            sx: { mt: "10px" },
            fullWidth: true,
            size: "small",
          }}
        />
      </TableCell>
      <TableCell align="right">
        <Button
          color="secondary"
          variant="contained"
          disabled={isProcessing}
          size="small"
          onClick={onAccountInvitation}
        >
          Invite
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRow;
