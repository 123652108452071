import { Upload } from "@mui/icons-material";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { attachmentUpload } from "../reducers/UsersReducer";
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";

const UploadFile = ({ disabled, onUploadStart, onComplete, currentPath }) => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState(null);

  const onCVUploadClick = () => {
    let fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.setAttribute("multiple", false);
    fileInput.onchange = (e) => {
      let formData = new FormData();
      formData.append("file", e.currentTarget.files[0]);

      let fileName = e.currentTarget.files[0].name;

      onUploadStart();
      dispatch(
        attachmentUpload({
          formData,
          onProgress: ({ progress }) => {
            setProgress(progress);
          },
          onComplete: () => {
            setProgress(0);
            setFileName(fileName);
          },
        })
      ).then(({ payload }) => {
        onComplete(payload);
        setProgress(0);
      });
    };
    fileInput.click();
  };

  let currentFileName = useMemo(() => {
    if (!currentPath) return null;

    let splitted = currentPath.split("/");
    return splitted[splitted.length - 1];
  }, [currentPath]);

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={onCVUploadClick}
          sx={{ my: 1 }}
          endIcon={<Upload />}
        >
          Upload CV
        </Button>
        <Typography sx={{ ml: 1 }}>{fileName ?? currentFileName}</Typography>
      </Box>
      {progress > 0 && (
        <LinearProgress variant="determinate" value={progress * 100} />
      )}
    </>
  );
};

export default UploadFile;
