import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { router } from "../routes";

const RequireAuthorization = ({
  role,
  redirect,
  render,
  renderProgress,
  children,
  requireAuthorization = true,
}) => {
  const { user, isProcessing } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isProcessing) return;

    if (
      (!requireAuthorization && user && redirect) ||
      (requireAuthorization &&
        user &&
        (role ? user.role === role : true) &&
        redirect)
    ) {
      router.navigate(redirect);
    }
  }, [isProcessing, redirect, requireAuthorization, role, user]);

  if (requireAuthorization && user === undefined && renderProgress)
    return <CircularProgress />;

  if (requireAuthorization && !isProcessing && user === undefined && render)
    return render;

  if (
    requireAuthorization &&
    !isProcessing &&
    user &&
    (role ? user.role === role : true)
  )
    return children;

  return !requireAuthorization && !user && <>{children}</>;
};

export default RequireAuthorization;
