import { Person } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/AuthReducer";
import { reset as resetRolesReducer } from "../../reducers/RolesReducer";
import { reset as resetAuthReducer } from "../../reducers/AuthReducer";
import { reset as resetUsersReducer } from "../../reducers/UsersReducer";

const UserController = () => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleUserMenuVisibility = (e) => {
    if (e && !anchorElUser) setAnchorElUser(e.currentTarget);
    else setAnchorElUser(null);
  };

  const onSignOutClick = () => {
    dispatch(logout());
    dispatch(resetRolesReducer());
    dispatch(resetAuthReducer());
    dispatch(resetUsersReducer());
    handleUserMenuVisibility();
  };

  return (
    <Box sx={{ flexGrow: 0, ml: "10px" }}>
      <Tooltip title="Open Settings">
        <IconButton
          className="nav-btn"
          onClick={handleUserMenuVisibility}
          sx={{ p: 0 }}
        >
          <Person sx={{ p: "5px", fontSize: "40px" }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleUserMenuVisibility}
      >
        <MenuItem disabled>{user.unique_name}</MenuItem>
        <Divider />
        <MenuItem onClick={onSignOutClick}>
          <Typography sx={{ textAlign: "center" }}>Sign Out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserController;
