import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../reducers/UsersReducer";
import UsersTableRow from "./UsersTableRow";

const UsersTable = ({
  filterValue,
  accountStatus,
  event,
  jobRole,
  onRowClick,
}) => {
  const dispatch = useDispatch();

  const { users, isUsersProcessing } = useSelector((state) => state.users);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [len, setLen] = useState(users?.length ?? 0);

  const visibleRows = useMemo(() => {
    let arr = users ?? [];
    if (
      filterValue ||
      (accountStatus !== undefined && accountStatus !== null) ||
      (event !== undefined && event !== null) ||
      (jobRole !== undefined && jobRole !== null)
    ) {
      arr = arr?.filter(
        (u) =>
          (filterValue
            ? u.name?.includes(filterValue) || u.email?.includes(filterValue)
            : true) &&
          (accountStatus !== undefined && accountStatus !== null
            ? u.accountStatus === accountStatus
            : true) &&
          (event !== null
            ? event === ""
              ? u.event === undefined || u.event === null
              : u.event === event
            : true) &&
          (jobRole !== null
            ? jobRole === ""
              ? !u.jobRole
              : u.jobRole === jobRole
            : true)
      );
    }
    setLen(arr.length);
    return (
      arr?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []
    );
  }, [users, filterValue, accountStatus, event, jobRole, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!isUsersProcessing && users === undefined) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterValue, accountStatus, event, jobRole]);

  if (users === undefined || isUsersProcessing)
    return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    );

  return users?.[0] ? (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className={"header"}>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">CV</TableCell>
              <TableCell align="center">Event</TableCell>
              <TableCell align="center" sx={{ textWrap: "nowrap" }}>
                Created Date
              </TableCell>
              <TableCell align="center">Job Role</TableCell>
              <TableCell align="right" sx={{ textWrap: "nowrap" }}>
                Mobile Number
              </TableCell>
              <TableCell align="right" sx={{ textWrap: "nowrap" }}>
                Account Status
              </TableCell>
              <TableCell align="right">User Status</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(({ id, ...values }) => (
              <UsersTableRow
                key={id}
                id={id}
                onClick={onRowClick}
                {...values}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="table-pagination"
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={len}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <Typography textAlign={"center"} variant="h6" color="white">
      No users found
    </Typography>
  );
};

export default UsersTable;
