import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  attachmentUpload,
  userCreate,
  userUpdate,
} from "../reducers/UsersReducer";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "@mui/icons-material";
import UploadFile from "./UploadCV";
import RoleSelector from "./RoleSelector";

const AddOrUpdateUserDialog = ({ open, handleClose, user }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, reset } = useForm();

  const { events, jobRoles } = useSelector((state) => state.users);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedPath, setUploadedPath] = useState(null);

  const onSubmit = (formData) => {
    const data = new FormData();
    Object.entries(formData).map(([key, val]) => data.append(key, val));
    if (uploadedPath) {
      data.append("cvPath", uploadedPath);
    }

    if (user) {
      data.append("id", user.id);
    }

    setIsSubmitting(true);
    dispatch(user ? userUpdate({ data }) : userCreate({ data })).then(() => {
      setIsSubmitting(false);
      onClose();
    });
  };

  const onUploadStart = () => setIsUploading(true);

  const onUploadEnd = (path) => {
    setIsUploading(false);
    setUploadedPath(path);
  };

  const onClose = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    reset();
  }, [user]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle>{user ? "Update User" : "Create New User"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Use this form to ${
          user ? "update" : "create new"
        } user.`}</DialogContentText>
        <TextField
          defaultValue={user?.email}
          disabled={isSubmitting}
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          required
          fullWidth
          variant="standard"
          {...register("email", { required: true })}
        />
        <TextField
          disabled={isSubmitting}
          autoFocus
          margin="dense"
          id="name"
          label="Full Name"
          type="text"
          required
          fullWidth
          variant="standard"
          autoComplete="name"
          defaultValue={user?.name}
          {...register("name", { required: true })}
        />
        <UploadFile
          currentPath={user?.cvPath}
          onUploadStart={onUploadStart}
          onComplete={onUploadEnd}
          disabled={isUploading || isSubmitting}
        />
        <Autocomplete
          options={events ?? []}
          disablePortal
          freeSolo
          defaultValue={user?.event}
          renderInput={(params) => (
            <TextField
              disabled={isSubmitting}
              autoFocus
              margin="dense"
              id="event"
              label="Event"
              type="text"
              fullWidth
              variant="standard"
              {...register("event")}
              {...params}
            />
          )}
        />
        <Autocomplete
          options={jobRoles ?? []}
          disablePortal
          freeSolo
          defaultValue={user?.jobRole}
          renderInput={(params) => (
            <TextField
              disabled={isSubmitting}
              autoFocus
              margin="dense"
              id="jobRole"
              label="Job Role"
              type="text"
              fullWidth
              variant="standard"
              {...register("jobRole")}
              {...params}
            />
          )}
        />
        <TextField
          disabled={isSubmitting}
          autoFocus
          margin="dense"
          id="mobileNumber"
          label="Mobile Number"
          type="number"
          fullWidth
          variant="standard"
          defaultValue={user?.mobileNumber}
          {...register("mobileNumber")}
        />
        <RoleSelector
          defaultValue={user?.role}
          containerProps={{
            sx: { mt: "10px" },
            fullWidth: true,
            size: "small",
          }}
          {...register("role")}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isUploading || isSubmitting} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isUploading || isSubmitting} type="submit">
          {user ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrUpdateUserDialog;
