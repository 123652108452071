import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Users from "./pages/Users";
import SignUp from "./pages/SignUp";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
    displayName: "Home",
    nav: true,
  },
  {
    path: "/signin",
    element: <SignIn />,
    displayName: "Sign In",
    nav: true,
    auth: true,
    requireAuthorization: false,
  },
  {
    path: "/signup",
    element: <SignUp />,
    displayName: "Sign Up",
    nav: true,
    auth: true,
    requireAuthorization: false,
  },
  {
    path: "/users",
    element: <Users />,
    displayName: "Users",
    nav: true,
    auth: true,
    role: "admin",
  },
];

const router = createBrowserRouter(routes);

export { router, routes };
