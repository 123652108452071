import Main from "./Main";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  return (
    <div
      className="page"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <Main>{children}</Main>
    </div>
  );
};

export default Layout;
