import { configureStore } from "@reduxjs/toolkit";
import ProcessReducer from "./reducers/ProcessReducer";
import NotificationsReducer from "./reducers/NotificationsReducer";
import AuthReducer from "./reducers/AuthReducer";
import UsersReducer from "./reducers/UsersReducer";
import RolesReducer from "./reducers/RolesReducer";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    process: ProcessReducer,
    notifications: NotificationsReducer,
    roles: RolesReducer,
    users: UsersReducer,
  },
});

export default store;
