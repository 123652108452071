import { Box, CircularProgress, Container, styled } from "@mui/material";
import ProcessingIndicator from "./ProcessingIndicator";
import NotificationBar from "./NotificationBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { authorizeUser } from "../reducers/AuthReducer";
import ApplicationLoadingProgressIndicator from "./ApplicationLoadingProgressIndicator";

const MainContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexGrow: 1,
}));

const Main = ({ children }) => {
  const dispatch = useDispatch();

  const { user, isProcessing } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user === undefined) {
      dispatch(authorizeUser());
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <ApplicationLoadingProgressIndicator isLoading={isLoading} />
      <ProcessingIndicator />
      <NotificationBar />
      {user !== undefined && !isProcessing && (
        <MainContainer
          maxWidth={"100%"}
          className="d-flex flex-column page-container"
          sx={{ p: "0 !important" }}
        >
          {children}
        </MainContainer>
      )}
    </>
  );
};

export default Main;
