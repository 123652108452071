import { Box, Button } from "@mui/material";
import { routes } from "../../routes";
import RequireAuthorization from "../RequiredAuthorization";

const NavbarLG = ({ onLinkClick }) => {
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
      }}
    >
      {routes.map(({ nav, auth, path, displayName, ...rest }) => {
        return (
          nav &&
          (auth ? (
            <RequireAuthorization key={displayName} {...rest}>
              <Button
                className="nav-btn"
                sx={{ mr: "8px" }}
                key={displayName}
                onClick={onLinkClick}
                to={path}
              >
                {displayName}
              </Button>
            </RequireAuthorization>
          ) : (
            <Button
              className="nav-btn"
              sx={{ mr: "8px" }}
              key={displayName}
              onClick={onLinkClick}
              to={path}
            >
              {displayName}
            </Button>
          ))
        );
      })}
    </Box>
  );
};

export { NavbarLG };
