import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { routes } from "../../routes";
import { useState } from "react";
import { ArrowBack, Backup } from "@mui/icons-material";
import Logo from "../../images/assessments-logo.png";
import RequireAuthorization from "../RequiredAuthorization";

const NavbarMobile = ({ onLinkClick, navContainer }) => {
  const [isNavLinkvOpen, setIsNavLinkOpen] = useState(false);

  const onMenuButtonClick = () => {
    setIsNavLinkOpen((state) => !state);
  };

  const onNavLinkClick = (e) => {
    onLinkClick(e);
    onMenuButtonClick();
  };

  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
      }}
    >
      <Button className="nav-btn" onClick={onMenuButtonClick}>
        Menu
      </Button>

      <Drawer
        container={navContainer}
        variant="temporary"
        open={isNavLinkvOpen}
        onClose={onMenuButtonClick}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "100vw" },
        }}
      >
        <AppBar
          className="nav-header"
          sx={{
            mb: "5px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            position: "sticky",
            justifyContent: "space-between",
          }}
          width={"100%"}
        >
          <IconButton
            className="nav-btn"
            sx={{ ml: "20px" }}
            onClick={onMenuButtonClick}
          >
            <ArrowBack />
          </IconButton>
          <img style={{ margin: "20px" }} alt="assessment-pg-icon" src={Logo} />
        </AppBar>
        {routes.map(({ auth, nav, path, displayName, ...rest }) => {
          return (
            nav &&
            (auth ? (
              <RequireAuthorization key={displayName} {...rest}>
                <Box
                  className="nav-btn"
                  onClick={onNavLinkClick}
                  to={path}
                  key={displayName}
                  textAlign={"center"}
                  sx={{
                    transition: "0.3s ease all",
                    cursor: "pointer",
                    py: "10px",
                  }}
                  width={"100%"}
                >
                  <Typography>{displayName}</Typography>
                </Box>
              </RequireAuthorization>
            ) : (
              <Box
                onClick={onNavLinkClick}
                to={path}
                key={displayName}
                textAlign={"center"}
                sx={{
                  transition: "0.3s ease all",
                  cursor: "pointer",
                  py: "10px",
                }}
                width={"100%"}
              >
                <Typography>{displayName}</Typography>
              </Box>
            ))
          );
        })}
      </Drawer>
    </Box>
  );
};

export { NavbarMobile };
