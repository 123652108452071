import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProcessing: false,
};

const slice = createSlice({
  name: "ProcessReducer",
  initialState: initialState,
  reducers: {
    setProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
  },
});

export const { setProcessing } = slice.actions;

export default slice.reducer;
