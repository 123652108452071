import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import LoginForm from "../components/LoginForm";
import RequireAuthorization from "../components/RequiredAuthorization";
import FCard from "../components/FCard";
import FCardContainer from "../components/FCardContainer";

export default function SignIn(props) {
  return (
    <RequireAuthorization redirect={"/"} requireAuthorization={false}>
      <CssBaseline enableColorScheme />
      <FCardContainer direction="column" justifyContent="space-between">
        <FCard variant="outlined">
          <Typography
            textAlign={"center"}
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign in
          </Typography>
          <Typography textAlign={"center"} variant="caption">
            If you have any trouble login, please contact our support unit.
          </Typography>
          <LoginForm />
        </FCard>
      </FCardContainer>
    </RequireAuthorization>
  );
}
