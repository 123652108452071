import React, { PropTypes, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  removeNotification,
  setActive,
} from "../reducers/NotificationsReducer";

const NotificationBar = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  useEffect(() => {
    Object.entries(notifications).forEach(([id, val]) => {
      if (val.active || val.showed) return;

      const { message, type } = val;

      toast(message, {
        type: type,
        theme: "colored",
        hideProgressBar: false,
      });
      dispatch(setActive(id));
      setTimeout(() => {
        dispatch(removeNotification(id));
      }, 1000);
    });
  }, [notifications]);

  return <ToastContainer />;
};

export default NotificationBar;
