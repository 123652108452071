import { Box, CircularProgress } from "@mui/material";

const ApplicationLoadingProgressIndicator = ({ isLoading }) => {
  return (
    isLoading && (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          zIndex: "10001",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    )
  );
};

export default ApplicationLoadingProgressIndicator;
