import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRoles } from "../api/requests";

const getAllRoles = createAsyncThunk("roles/get", async (_, { dispatch }) => {
  let { data } = await getRoles(dispatch);
  return data;
});

const initialState = {
  isRolesProcessing: false,
  roles: undefined,
};

const slice = createSlice({
  name: "RolesReducer",
  initialState: initialState,
  reducers: {
    setRolesProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.isRolesProcessing = true;
      })
      .addCase(getAllRoles.fulfilled, (state, { payload }) => {
        state.roles = payload;
        state.isRolesProcessing = false;
      })
      .addCase(getAllRoles.rejected, (state) => {
        state.roles = null;
        state.isRolesProcessing = false;
      }),
});

export const { setRolesProcessing, reset } = slice.actions;

export default slice.reducer;

export { getAllRoles };
