const SERVER = process.env.REACT_APP_API ?? "https://localhost:7038";
const API = SERVER + "/api/v1";
const STATIC_PATH = `${SERVER}/${process.env.REACT_APP_API_STATIC_SUFFIX}`;

const AUTH_URI = "/Auth";
const OAUTH_URI = "/OAuth";
const USERS_URI = "/Users";
const ATTACHMENTS_URI = "/Attachments";

const constructUrl = (uri, endpoint) => {
  return API + uri + (endpoint ?? "");
};

const AUTH = {
  GET_PUBLIC_KEY: {
    method: "get",
    url: constructUrl(AUTH_URI, "/PublicKey"),
  },
  LOGIN: {
    method: "post",
    url: constructUrl(AUTH_URI, "/Login"),
  },
  SIGN_UP: {
    method: "post",
    url: constructUrl(AUTH_URI, "/SignUp"),
  },
};

const OAUTH = {
  AUTHENTICATE: {
    method: "post",
    url: constructUrl(OAUTH_URI, "/GoogleAuthenticate"),
  },
};

const USERS = {
  CREATE: {
    method: "post",
    url: constructUrl(USERS_URI, "/Create"),
  },
  UPDATE: {
    method: "post",
    url: constructUrl(USERS_URI, "/Update"),
  },
  UPDATE_ACTIVATE_STATUS: {
    method: "post",
    url: constructUrl(USERS_URI, "/UpdateActivateStatus"),
  },
  GET: {
    method: "get",
    url: constructUrl(USERS_URI),
  },
  ROLES: {
    method: "get",
    url: constructUrl(USERS_URI, "/Roles"),
  },
  ASSIGN_ROLE: {
    method: "post",
    url: constructUrl(USERS_URI, "/AssignRole"),
  },
  INVITE: {
    method: "post",
    url: constructUrl(USERS_URI, "/Invite"),
  },
};

const ATTACHMENTS = {
  UPLOAD: {
    method: "post",
    url: constructUrl(ATTACHMENTS_URI, "/Upload"),
  },
};

export { AUTH, OAUTH, USERS, ATTACHMENTS, STATIC_PATH };
