import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ProcessingIndicator = ({ isOpen, opacity }) => {
  const { isProcessing } = useSelector((state) => state.process);

  return (
    (isProcessing || isOpen) && (
      <Fade in={isOpen ? isOpen : !!isProcessing}>
        <Box
          className="indicator-container"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: `rgba(200,200,200,${opacity ?? "0.5"})`,
            position: "absolute",
            zIndex: "1000",
            top: "0",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: "#1a90ff",
              animationDuration: "550ms",
              left: 0,
              strokeLinecap: "round",
            }}
            size={40}
            thickness={4}
          />
          {typeof isProcessing === "string" && (
            <Typography
              sx={{ margin: "15px", fontFamily: "Poppins", fontWeight: 500 }}
            >
              {isProcessing}
            </Typography>
          )}
        </Box>
      </Fade>
    )
  );
};

export default ProcessingIndicator;
