import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { userSignUp } from "../reducers/AuthReducer";
import GoogleOAuth from "./GoogleOAuth";
import { GoogleOAuthProvider } from "@react-oauth/google";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((state) => !state);
  };

  const onSubmit = (formData) => {
    let errors = {};
    if (formData["password"].length < 8) {
      errors["password"] = "Password needs at least 8 characters.";
    } else if (
      !formData["password"].match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      errors["password"] =
        "Password needs at least 1 number and a non alphanumeric character.";
    } else if (formData["password"] !== formData["confirmPassword"]) {
      errors["password"] = "Passwords do not match.";
      errors["confirmPassword"] = "Passwords do not match.";
    } else if (
      formData["mobileNumber"].length > 0 &&
      formData["mobileNumber"].length < 9
    ) {
      errors["mobileNumber"] = "Invalid mobile number.";
    }
    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});

    const data = new FormData();
    Object.entries(formData).map(([key, val]) => data.append(key, val));

    setIsProcessing(true);
    dispatch(userSignUp({ data })).then(() => {
      setIsProcessing(false);
    });
  };

  return (
    <Box
      disabled={isProcessing}
      component={"form"}
      width={"100%"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        fullWidth
        disabled={isProcessing}
        error={!!errors["email"]}
        helperText={errors["email"]}
        label="Email"
        {...register("email", { required: true })}
        id="email"
        type="email"
        placeholder="Email"
        autoComplete="email"
        variant="standard"
        sx={{ ariaLabel: "email" }}
      />
      <Box display={"flex"} flexWrap={"wrap"}>
        <TextField
          disabled={isProcessing}
          error={!!errors["firstName"]}
          helperText={errors["firstName"]}
          label="First Name"
          {...register("firstName", { required: true })}
          id="firstName"
          type="text"
          placeholder="First Name"
          autoComplete="firstName"
          variant="standard"
          sx={{ ariaLabel: "firstName", pr: "5px", flexGrow: 1, mt: "10px" }}
        />
        <TextField
          disabled={isProcessing}
          error={!!errors["lastName"]}
          helperText={errors["lastName"]}
          label="Last Name"
          {...register("lastName", { required: true })}
          id="lastName"
          type="text"
          placeholder="Last Name"
          autoComplete="lastName"
          variant="standard"
          sx={{ ariaLabel: "lastName", flexGrow: 1, mt: "10px" }}
        />
      </Box>
      <TextField
        fullWidth
        disabled={isProcessing}
        error={!!errors["password"]}
        helperText={errors["password"]}
        label="Password"
        id="password"
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        autoComplete="password"
        variant="standard"
        sx={{ ariaLabel: "password", mt: "10px" }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment sx={{ ml: "5px" }} position="end">
                <IconButton
                  sx={{ m: 0 }}
                  type="button"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        {...register("password", { required: true })}
      />
      <TextField
        fullWidth
        disabled={isProcessing}
        error={!!errors["confirmPassword"]}
        helperText={errors["confirmPassword"]}
        label="Confirm Password"
        id="confirmPassword"
        type={showConfirmPassword ? "text" : "password"}
        placeholder="Confirm Password"
        autoComplete="confirmPassword"
        variant="standard"
        sx={{ ariaLabel: "confirmPassword", mt: "10px" }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment sx={{ ml: "5px" }} position="end">
                <IconButton
                  sx={{ m: 0 }}
                  type="button"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        {...register("confirmPassword", { required: true })}
      />
      <TextField
        id="input-with-icon-textfield"
        label="Mobile Number"
        disabled={isProcessing}
        error={!!errors["mobileNumber"]}
        helperText={errors["mobileNumber"]}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Typography>+94</Typography>
              </InputAdornment>
            ),
          },
        }}
        variant="standard"
        type="number"
        fullWidth
        sx={{ ariaLabel: "mobileNumber", flexGrow: 1, mt: "10px" }}
        {...register("mobileNumber")}
      />
      <Button
        disabled={isProcessing}
        fullWidth
        sx={{ mt: "20px" }}
        variant="contained"
        type="submit"
      >
        Sign Up
      </Button>
      <Divider sx={{ my: "15px" }}>Or</Divider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onScriptLoadSuccess={console.log}
        onScriptLoadError={console.error}
      >
        <GoogleOAuth setIsProcessing={setIsProcessing} shouldRemember={true} />
      </GoogleOAuthProvider>
    </Box>
  );
};

export default SignUpForm;
