const VARIABLES = {
  LOCAL_STORAGE_AUTH_TOKEN: "_authToken",
  LOCAL_STORAGE_PUBLIC_KEY: "_publicKey",
};

const LOADING_DIALOGS = {
  AUTHORIZING: "Authorizing...",
};

const NOTIFICATIONS = {
  REGISTER_SUCCESS: "Successfully created account.",
};

const ERROR_MESSAGES = {
  PUBLIC_KEY_FETCHING_ERROR: "Failed to retrieve public key from server",
};

const ACCOUNT_STATUS = {
  Created: 0,
  Invited: 1,
  Active: 2,
};

export {
  VARIABLES,
  LOADING_DIALOGS,
  ERROR_MESSAGES,
  NOTIFICATIONS,
  ACCOUNT_STATUS,
};
