import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignRole,
  createUser,
  getUsers,
  inviteUser,
  updateActivateStatus,
  updateUser,
  uploadAttachment,
} from "../api/requests";
import { ACCOUNT_STATUS } from "../util/constants";
import { addNotification } from "./NotificationsReducer";

const getAllUsers = createAsyncThunk("users/get", async (_, { dispatch }) => {
  let { data } = await getUsers(dispatch);

  let events = new Set();
  let jobRoles = new Set();

  data.forEach((u) => {
    if (u.event && !events.has(u.event)) events.add(u.event);
    if (u.jobRole && !jobRoles.has(u.jobRole)) jobRoles.add(u.jobRole);
  });

  return {
    users: data?.sort((u1, u2) => u2.created.localeCompare(u1.created)),
    events: Array.from(events).sort((u1, u2) => u1.localeCompare(u2)),
    jobRoles: Array.from(jobRoles).sort((u1, u2) => u1.localeCompare(u2)),
  };
});

const userCreate = createAsyncThunk(
  "users/create",
  async ({ data: formData }, { dispatch }) => {
    let { data } = await createUser(dispatch, formData);
    return data;
  }
);

const userUpdate = createAsyncThunk(
  "users/update",
  async ({ data: formData }, { dispatch }) => {
    let { data } = await updateUser(dispatch, formData);
    return data;
  }
);

const updateAccountStatus = createAsyncThunk(
  "users/updateStatus",
  async ({ id, isActive }, { dispatch }) => {
    await updateActivateStatus(dispatch, id, isActive);
    return { id, isActive };
  }
);

const attachmentUpload = createAsyncThunk(
  "users/attachment",
  async ({ formData, onProgress, onComplete }, { dispatch }) => {
    let { data } = await uploadAttachment(dispatch, formData, onProgress);
    onComplete?.();
    return data;
  }
);

const sendUserInvitation = createAsyncThunk(
  "users/invite",
  async (userId, { dispatch }) => {
    await inviteUser(dispatch, userId);
    dispatch(
      addNotification({
        message: "Invitation sent successfully",
        type: "info",
      })
    );
    return userId;
  }
);

const assignRoleToUser = createAsyncThunk(
  "users/assignRole",
  async ({ email, role }, { dispatch }) => {
    await assignRole(dispatch, email, role);
    return { email, role };
  }
);

const initialState = {
  isUsersProcessing: false,
  users: undefined,
  events: undefined,
  jobRoles: undefined,
};

const slice = createSlice({
  name: "UsersReducer",
  initialState: initialState,
  reducers: {
    setProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllUsers.pending, (state, { payload }) => {
        state.isUsersProcessing = true;
      })
      .addCase(
        getAllUsers.fulfilled,
        (state, { payload: { users, events, jobRoles } }) => {
          state.users = users;
          state.events = events;
          state.jobRoles = jobRoles;
          state.isUsersProcessing = false;
        }
      )
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.users = null;
        state.isUsersProcessing = false;
      })
      .addCase(userUpdate.fulfilled, (state, { payload }) => {
        let users = state.users?.filter((user) => user.id !== payload.id);

        state.users = [payload, ...users];
        if (payload.event && !state.events.includes(payload.event)) {
          state.events = [payload.event, ...state.events];
        }
        if (payload.jobRole && !state.jobRoles.includes(payload.jobRole)) {
          state.jobRoles = [payload.jobRole, ...state.jobRoles];
        }
      })
      .addCase(userCreate.fulfilled, (state, { payload }) => {
        if (!state.users) {
          state.users = [];
        }

        state.users = [payload, ...state.users];
        if (payload.event && !state.events.includes(payload.event)) {
          state.events = [payload.event, ...state.events];
        }
        if (payload.jobRole && !state.jobRoles.includes(payload.jobRole)) {
          state.jobRoles = [payload.jobRole, ...state.jobRoles];
        }
      })
      .addCase(
        updateAccountStatus.fulfilled,
        (state, { payload: { id, isActive } }) => {
          let user = state.users?.find((user) => user.id === id);
          user.isActive = isActive;
        }
      )
      .addCase(
        assignRoleToUser.fulfilled,
        (state, { payload: { email, role } }) => {
          let user = state.users?.find((user) => user.email === email);
          user.role = role;
        }
      )
      .addCase(sendUserInvitation.fulfilled, (state, { payload }) => {
        let user = state.users?.find((user) => user.id === payload);
        user.accountStatus = ACCOUNT_STATUS.Invited;
      }),
});

export const { setProcessing, reset } = slice.actions;

export default slice.reducer;

export {
  userCreate,
  userUpdate,
  updateAccountStatus,
  getAllUsers,
  attachmentUpload,
  sendUserInvitation,
  assignRoleToUser,
};
