import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: {},
};

const slice = createSlice({
  name: "NotificationReducer",
  initialState: initialState,
  reducers: {
    addNotification: (state, { payload }) => {
      if (!payload.id) {
        payload.id = Math.random() * 1000 + "";
      }

      const { id, message, type } = payload;
      state.notifications = {
        ...state.notifications,
        [id]: { message, type },
      };
    },
    setActive: (state, { payload }) => {
      if (state.notifications[payload])
        state.notifications[payload].active = true;
    },
    removeNotification: (state, { payload }) => {
      if (state.notifications[payload]) delete state.notifications[payload];
    },
  },
});

export const { addNotification, setActive, removeNotification } = slice.actions;

export default slice.reducer;
