import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOADING_DIALOGS, VARIABLES } from "../util/constants";
import {
  getPublicKey,
  oauthAuthenticate,
  postLogin,
  postSignUp,
} from "../api/requests";
import { router } from "../routes";
import { setProcessing as setPageProcessing } from "./ProcessReducer";
import axios from "axios";
import { pki } from "node-forge";
import {
  decodeProtectedHeader,
  importPKCS8,
  importSPKI,
  jwtVerify,
} from "jose";
import { addNotification } from "./NotificationsReducer";

const authorizeUser = createAsyncThunk(
  "auth/authorizeUser",
  async (_, { dispatch }) => {
    dispatch(setPageProcessing(LOADING_DIALOGS.AUTHORIZING));
    try {
      let token =
        localStorage.getItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN) ??
        sessionStorage.getItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      if (token) {
        axios.defaults.headers.common = {
          Authorization: "Bearer " + token,
        };

        let publicKey = localStorage.getItem(
          VARIABLES.LOCAL_STORAGE_PUBLIC_KEY
        );
        if (!publicKey) {
          const { payload } = await dispatch(updatePublicKey());
          publicKey = payload;
        }
        if (publicKey.startsWith("-----BEGIN RSA PUBLIC KEY-----")) {
          const key = pki.publicKeyToPem(pki.publicKeyFromPem(publicKey));
          const { alg } = decodeProtectedHeader(token);
          publicKey = await importSPKI(key, alg);
        } else publicKey = await importPKCS8(publicKey);

        dispatch(setPageProcessing(false));
        if (!publicKey) {
          return false;
        }
        return jwtVerify(token, publicKey);
      }
      dispatch(setPageProcessing(false));
    } catch (error) {
      dispatch(setPageProcessing(false));
      throw error;
    }
  }
);

const updatePublicKey = createAsyncThunk(
  "auth/updatePublicKey",
  async (_, { dispatch }) => {
    let { data } = await getPublicKey(dispatch);
    return data;
  }
);

const userLogin = createAsyncThunk(
  "auth/login",
  async ({ data: formData, shouldRemember }, { dispatch }) => {
    let { data } = await postLogin(dispatch, formData);
    return { shouldRemember, ...data };
  }
);

const userSignUp = createAsyncThunk(
  "auth/signUp",
  async ({ data }, { dispatch }) => {
    await postSignUp(dispatch, data);
    dispatch(
      addNotification({
        message: "Successfully created account. Please log in.",
        type: "success",
      })
    );
    return {};
  }
);

const userGoogleOAuth = createAsyncThunk(
  "auth/oauth/google",
  async ({ data: formData, shouldRemember }, { dispatch }) => {
    let { data } = await oauthAuthenticate(dispatch, formData);
    return { shouldRemember, ...data };
  }
);

const initialState = {
  isProcessing: false,
  user: undefined,
  isAuthenticated: false,
};

const slice = createSlice({
  name: "AuthSlice",
  initialState: initialState,
  reducers: {
    setAuthProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      sessionStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      localStorage.removeItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY);

      router.navigate("/");
    },
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        userLogin.fulfilled,
        (state, { payload: { accessToken, shouldRemember } = {} }) => {
          if (accessToken) {
            (shouldRemember ? localStorage : sessionStorage).setItem(
              VARIABLES.LOCAL_STORAGE_AUTH_TOKEN,
              accessToken
            );
          }
          router.navigate("/");
        }
      )
      .addCase(
        userGoogleOAuth.fulfilled,
        (state, { payload: { accessToken, shouldRemember } = {} }) => {
          if (accessToken) {
            (shouldRemember ? localStorage : sessionStorage).setItem(
              VARIABLES.LOCAL_STORAGE_AUTH_TOKEN,
              accessToken
            );
          }
          router.navigate("/");
        }
      )
      .addCase(authorizeUser.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(authorizeUser.rejected, (state, { payload }) => {
        localStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
        sessionStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
        localStorage.removeItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY);
        state.isProcessing = false;
        state.user = null;
      })
      .addCase(authorizeUser.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = payload.payload;
          state.isAuthenticated = true;
        } else {
          state.user = null;
          localStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
          sessionStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
        }
        state.isProcessing = false;
      })
      .addCase(userSignUp.fulfilled, (state, { payload }) => {
        router.navigate("/signin");
      })
      .addCase(updatePublicKey.fulfilled, (state, { payload }) => {
        if (payload)
          localStorage.setItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY, payload);
      });
  },
});

export default slice.reducer;

export const { setAuthProcessing, logout, reset } = slice.actions;

export { userLogin, authorizeUser, userSignUp, userGoogleOAuth };
