import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "../components/UsersTable";
import UserFilter from "../components/UserFilter";
import AddOrUpdateUserDialog from "../components/AddOrUpdateUserDialog";

const Users = () => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

  const [filterValue, setFilterValue] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);
  const [event, setEvent] = useState(null);
  const [jobRole, setJobRole] = useState(null);
  const [showEnabledUsersOnly, setShowEnabledUsersOnly] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

  const onAddNewUserButtonClick = () => {
    setIsAddUserDialogOpen((state) => !state);
    if (isAddUserDialogOpen) {
      setCurrentSelectedUser(null);
    }
  };

  const onUserTableRowClick = (values) => {
    setCurrentSelectedUser(values);
    onAddNewUserButtonClick();
  };

  return (
    <Container maxWidth="lg">
      <UserFilter
        filterValue={filterValue}
        accountStatus={accountStatus}
        setFilterValue={setFilterValue}
        setAccountStatus={setAccountStatus}
        event={event}
        setEvent={setEvent}
        jobRole={jobRole}
        setJobRole={setJobRole}
        showEnabledUsersOnly={showEnabledUsersOnly}
        setshowEnabledUsersOnly={setShowEnabledUsersOnly}
      />
      <Divider />
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={onAddNewUserButtonClick}
          sx={{ my: 2 }}
          size="small"
          variant="contained"
          color="secondary"
        >
          Add
        </Button>
        <AddOrUpdateUserDialog
          user={currentSelectedUser}
          handleClose={onAddNewUserButtonClick}
          open={isAddUserDialogOpen}
        />
      </Box>
      <UsersTable
        event={event}
        jobRole={jobRole}
        filterValue={filterValue}
        accountStatus={accountStatus}
        showEnabledUsersOnly={showEnabledUsersOnly}
        onRowClick={onUserTableRowClick}
      />
    </Container>
  );
};

export default Users;
