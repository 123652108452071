import { CssBaseline, Typography } from "@mui/material";
import RequireAuthorization from "../components/RequiredAuthorization";
import FCard from "../components/FCard";
import FCardContainer from "../components/FCardContainer";
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {
  return (
    <RequireAuthorization redirect={"/"} requireAuthorization={false}>
      <CssBaseline enableColorScheme />
      <FCardContainer direction="column" justifyContent="space-between">
        <FCard variant="outlined">
          <Typography
            textAlign={"center"}
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign up
          </Typography>
          <Typography textAlign={"center"} variant="caption">
            If you have any trouble signing up, please contact our support unit.
          </Typography>
          <SignUpForm />
        </FCard>
      </FCardContainer>
    </RequireAuthorization>
  );
};

export default SignUp;
