import { createRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { authorizeUser, userLogin } from "../reducers/AuthReducer";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setProcessing as setProcessProcessing } from "../reducers/ProcessReducer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleOAuth from "./GoogleOAuth";

const LoginForm = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [shouldRemember, setShouldRemember] = useState(false);

  const emailInputRef = createRef();
  const passwordInputRef = createRef();

  const setIsProcessing = (value) => {
    dispatch(setProcessProcessing(value));
  };

  const onSubmit = (formData) => {
    if (emailError || passwordError) {
      return;
    }

    const data = new FormData();
    Object.entries(formData).map(([key, val]) => data.append(key, val));

    setIsProcessing("Signing in");
    dispatch(userLogin({ data, shouldRemember })).then(() => {
      dispatch(authorizeUser());
    });
  };

  const validateInputs = () => {
    const email = emailInputRef.current;
    const password = passwordInputRef.current;

    let isValid = true;
    if (!email?.value) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid Email.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password?.value || password?.value?.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 8 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      <FormControl>
        <FormLabel htmlFor="email">Email</FormLabel>
        <TextField
          inputRef={emailInputRef}
          error={emailError}
          helperText={emailErrorMessage}
          size="small"
          {...register("email", { required: true })}
          id="email"
          type="email"
          placeholder="Email"
          autoComplete="email"
          fullWidth
          variant="outlined"
          sx={{ ariaLabel: "email" }}
        />
      </FormControl>
      <FormControl>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormLabel htmlFor="password">Password</FormLabel>
        </Box>
        <TextField
          inputRef={passwordInputRef}
          error={passwordError}
          helperText={passwordErrorMessage}
          size="small"
          {...register("password", { required: true })}
          placeholder="••••••"
          type={showPassword ? "text" : "password"}
          id="password"
          autoComplete="password"
          autoFocus
          required
          fullWidth
          variant="outlined"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="button"
                    sx={{
                      border: "none",
                      background: "transparent !important",
                      py: "2px",
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            value="remember"
            color="primary"
            onChange={(e) => setShouldRemember(e.currentTarget.checked)}
          />
        }
        label="Remember me"
      />
      <Button
        onClick={validateInputs}
        type="submit"
        fullWidth
        variant="contained"
      >
        Sign in
      </Button>
      <Divider>Or</Divider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onScriptLoadSuccess={console.log}
        onScriptLoadError={console.error}
      >
        <GoogleOAuth
          setIsProcessing={setIsProcessing}
          shouldRemember={shouldRemember}
        />
      </GoogleOAuthProvider>
    </Box>
  );
};

export default LoginForm;
